<section class = "ERP mt-3 bg-white">
  <div class="container">
    <div class="row" style = "display: flex;justify-content: center;align-items: center;text-align: center;">
      <h2 class = "heading" style = "margin-top: 50px;">
        <hr />
        Our ERP and <br /> business  approach
      </h2>
    </div>
    <div class="row mt-5 erpDataList">
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/planning.png" class="img-fluid" alt = "Strategic Planning"
            style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Strategic Planning</h4>
              <p class = "content">
                Strategic planning in the context of an ERP system is crucial for the successful implementation and utilization of such a complex and transformative business solution.
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/analysis.png" class="img-fluid" alt = "Advanced Analytics"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Advanced Analytics</h4>
              <p class = "content">
                Integrating advanced analytics into an ERP system can significantly enhance an organization's ability to make data-driven decisions, improve operational efficiency, and gain valuable insights.
              </p>
            </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/automation.png" class="img-fluid" alt = "Process Automation"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Process Automation</h4>
              <p class = "content">
                Leveraging automation within ERP systems enhances efficiency by streamlining tasks, minimizing errors, and increasing productivity, ultimately optimizing business processes.
                <!-- Integrating process automation in ERP systems uses technology to streamline routine tasks, improving operational efficiency, reducing manual errors, and boosting overall productivity, thus optimizing business process execution. -->
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/intelligence.png" class="img-fluid" alt = "Data & Intelligence"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Data & Intelligence</h4>
              <p class = "content">

                Leveraging data intelligence in ERP systems enhances decision-making, process optimization, outcome prediction, and operational efficiency for improved performance.                <!-- Data intelligence involves collecting, analyzing, and utilizing data within ERP systems to improve decision-making, optimize processes, extract insights, predict outcomes, and boost operational efficiency and effectiveness. -->
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/reliability.png" class="img-fluid" alt = "Digital Assurance"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Digital Assurance</h4>
              <p class = "content">
                Digital assurance in ERP involves ensuring system reliability, security, and efficiency through various testing and validation methods. It includes assessing functionality, usability, and compliance to reduce risks.
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/growth.png" class="img-fluid" alt = "Dynamic Growth"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Dynamic Growth</h4>
              <p class = "content">
                Dynamic growth within an ERP system signifies its capacity to swiftly adapt and scale, meeting evolving business requirements and facilitating agile organizational development.
                <!-- Dynamic growth in an ERP system refers to its capability to quickly adapt and scale in response to changing business requirements and growing demands, facilitating agile and responsive organizational development. -->
              </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/ux.png" class="img-fluid" alt = "Mobility & UX"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Mobility & UX</h4>
              <p class = "content">
                Incorporating mobility into ERP systems and enhancing user experience are crucial for modern businesses, aiming to enhance accessibility, flexibility, and satisfaction, meeting digital expectations.
                <!-- Integrating mobility into ERP systems and optimizing UX is essential for contemporary businesses, aiming to boost accessibility, flexibility, and user satisfaction, aligning with today's digital and mobile-first expectations. -->
              </p>
            </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-4">
        <div class = "erpData">
          <img src="../../../../assets/images/Business_Approach/data-transformation.png" class="img-fluid" alt = "Cloud Transformation"
          style = "padding: 0px 20px 20px 20px;height:110px;width:110px;margin-bottom: auto;">
          <div>
              <h4 style = "text-align:left;padding-left: 30px;">Cloud Transformation</h4>
              <p class = "content">
                Craft a cohesive cloud strategy in line with organizational objectives. Determine the scope of ERP system's cloud transition, selecting deployment models (public, private, or hybrid) to fulfill business goals.
                <!-- Develop a comprehensive cloud strategy aligned with organizational goals. Define the ERP system's cloud transformation scope, including selecting deployment models like public, private, or hybrid, to meet business objectives. -->
              </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

