import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from 'src/app/blogging/blog.service';

@Component({
  selector: 'app-featurewala',
  templateUrl: './featurewala.component.html',
  styleUrls: ['./featurewala.component.css']
})
export class FeaturewalaComponent implements OnInit {

  blogs: any[] = [];
  currentPage: number = 1;
  totalPages: number = 1;
  pageSize: number = 3;
  hide: number;

  constructor(
    private blogService: BlogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadBlogs();
    this.blogService.leadAdded$.subscribe(() => {
      this.loadBlogs();
    });
  }

  loadBlogs() {
    this.blogService.getAllBlogs(this.pageSize, this.currentPage).subscribe(
      (response: any) => {
        this.blogs = response.data.pageData; // Assuming the API returns data in a 'data' property
        this.totalPages = response.data.totalPages; // Assuming the API returns total pages
        this.hide = this.blogs.length;
      },
      (error: any) => {
        alert('Error fetching blogs');
      }
    );
  }

  navigateToBlog(blogId: string): void {
    this.router.navigate(['blogging/view-blog', blogId]);
  }

}
