<section class = "Projects" style = "background: rgba(247, 247, 250, 1);">

  <div class = "container-fluid">
    <div class="row" style = "display: flex;justify-content: center;align-items: center;text-align: center;">
      <h2 class = "heading" style = "margin-top: 50px;">
        <hr />
        Our Products
      </h2>
    </div>
    <div class="row" style = "display: flex;justify-content: space-evenly;align-items: center;flex-wrap: wrap;margin-top: 50px;cursor: pointer;">
      <div class = "currentProjects" style = "display: flex;flex-direction: column;align-items: center;flex-basis: 300px;margin-bottom: 40px;">
        <img src="assets\images\erp.png" class="img-fluid" style = "padding: 8px 16px;height: 200px;">
        <h3 style = "margin: 8px 0px;">ERP</h3>
        <p class="para" style = "margin-bottom: 20px;padding: 8px 16px;">
          A top-tier ERP provider, delivering a comprehensive applications for finance, supply chain, manufacturing, and other business functions with global recognition.
        </p>
        <a style = "margin-bottom: 10px;cursor: pointer;" [routerLink]="['/moreinfo/product-erp']">Learn More</a>
      </div>
      <div class = "currentProjects" style = "display: flex;flex-direction: column;align-items: center;flex-basis: 300px;margin-bottom: 40px;cursor: pointer;">
        <img src="assets\images\fleet.png" class="img-fluid" style = "padding: 8px 16px;height: 200px;">
        <h3 style = "margin: 8px 0px;">Fleet Management</h3>
        <p class="para" style = "margin-bottom: 20px;padding: 8px 16px;">
          ERP streamlines fleet management by efficiently tracking and managing vehicles, optimizing routes, scheduling maintenance, and ensuring regulatory compliance.
        </p>
        <a style = "margin-bottom: 10px;cursor: pointer;" [routerLink]="['/moreinfo/product-fleet-management']">Learn More</a>
      </div>
      <div class = "currentProjects" style = "display: flex;flex-direction: column;align-items: center;flex-basis: 300px;margin-bottom: 40px;cursor: pointer;">
        <img src="assets\images\mobile_app.png" class="img-fluid" style = "padding: 8px 16px;height: 200px;">
        <h3 style = "margin: 8px 0px;">Mobile App</h3>
        <p class="para" style = "margin-bottom: 20px;padding: 8px 16px;">
          The app enables remote shopping and manages warranties effortlessly, enhancing convenience by digitalizing warranty and guarantee management within the platform.
        </p>
        <a style = "margin-bottom: 10px;cursor: pointer;" [routerLink]="['/moreinfo/product-mobile-management']">Learn More</a>
      </div>
    </div>
  </div>

</section>



