<div appScrollspy>

  <section style = "padding: 50px 0 0 !important;" id="home">
    <div class="container-fluid">

      <div class="row">

        <div class="col-sm-12 col-md-12 col-lg-6 left-section">
          <div class = "heading" style = "margin: 70px 0px 0px 50px;">
            <span>"Introducing the world's <span style = "color: rgba(252, 118, 67, 1);">first ERP</span> system <br />
              empowered by cutting-edge <span style = "color: rgba(252, 118, 67, 1);">AI</span> technology."</span>
          </div>
          <br />
          <div class = "subHeading" style = "margin: 35px 90px 0px 50px;">
            "A digital platform designed to simplify your life, offering an efficient solution for managing all your business needs in one place. We prioritize your time and privacy, ensuring a seamless experience."<br />
          </div>
          <div class = "mobileApp" style = "margin: 70px 0px 10px 38px;">
            <button class="btn"
              onclick=" window.open('https://web.iceipts.com/#/auth/login','_blank')" target="_blank">
                <span class = "btn btn-warning"
                style = "height: 33px;display: flex;align-items: center;">
                Let's get started !</span>
            </button>
            <button class="btn"  (click)="openPopup()">
                <span class = "getStarted">Mobile App</span>
            </button>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 right-section">
          <div class="carousel-container">
            <ngb-carousel [interval]="0" (slide)="onSlide($event.current)" class="custom-carousel" style = "display: flex;justify-content: center;align-items: center;">
              <ng-template ngbSlide *ngFor="let image of images; let i = index">
                <img [src]="image" alt="Slide {{ i + 1 }}" class="sliderImages">
              </ng-template>
            </ngb-carousel>
            <div class="progress-container">
              <div class="progress-bar" [style.width]="progressPercentage + '%'" [ngClass]="{'progressColor': progressPercentage}"></div>
              <span class = "firstNumber">{{ (+this.temp + 1) | number:'2.0' }}</span>
              <span class = "lastNumber">{{ this.totalSlides | number:'2.0' }}</span>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>

</div>

<div *ngIf="showPopup" class="modal-overlay">
  <div class="popup-overlay">
    <div class="popup-content">
      <h2>Coming Soon</h2>
      <p>Our mobile app is under development. Stay tuned!</p>
      <button class = "btn btn-warning" (click)="closePopup()">Close</button>
    </div>
  </div>
</div>


<app-whyiceiptwala></app-whyiceiptwala>
<app-servicewala></app-servicewala>
<app-aboutwala></app-aboutwala>
<app-clientwala></app-clientwala>
<app-featurewala></app-featurewala>
<app-footerwala></app-footerwala>


<df-messenger
  intent="WELCOME"
  chat-title="iceipts_official_website"
  agent-id="84d2263a-cdb5-4492-a0c3-4dbcb3975b78"
  language-code="en"
></df-messenger>