<section>
  <div class = "container-fluid">
    <div class = "commentSection">
      <hr />
      <h2 class = "heading">Why Customers love <br /> working with us</h2>
      <p class="comment">
        {{ currentCustomer?.customerComment }}
      </p>
      <img src = "../../../../assets/images/arrow-left.png" alt = "left-arrow" class = "leftArrow" (click)="onArrowClick('left')">
      <img src = "../../../../assets/images/arrow-left.png" alt = "right-arrow" class = "rightArrow" (click)="onArrowClick('right')">
    </div>
    <div class = "customerSection">
      <div class = "customers" *ngFor="let item of customer; let i = index" (slide)="onSlide($event.current)">
        <img [src]="item.customerImage" alt="" class="img-fluid d-block rounded-circle" width="150" height="150" [ngClass]="{'active': i === currentSlide, 'inactive': i !== currentSlide}">
        <h6 class="customerName" style = "font-size: 18px;color: #000;" [ngClass]="{'active': i === currentSlide, 'inactive': i !== currentSlide}">{{ item.customerName }}</h6>
        <p class="customerOccupation" style = "font-size: 16px;color:#000;" [ngClass]="{'active': i === currentSlide, 'inactive': i !== currentSlide}">{{ item.customerOccupation }}</p>
      </div>
    </div>
  </div>
</section>
