import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogging',
  templateUrl: './blogging.component.html',
  styleUrls: ['./blogging.component.css']
})
export class BloggingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
