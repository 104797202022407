<section class = "footer mb-0 pb-0" style = "height: 100vh;">

  <div class="container-fluid mainContainer">

    <div class = "heading">
      "The technology deemed impossible <br />
      today will seamlessly integrate <br />
      into our lives tomorrow."
    </div>

    <div class="container-fluid">

      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12 contents addressLeft">
          <span style="font-weight: 500;color: white;margin-bottom: 12px;">Address</span>
          <div>
            <img src="../../../../assets/images/footer/footer-address.png" alt="Indian Address" style="width:14px;height:20px;">
            <span> India - </span>
            <span>Baner-Pashan Link Rd Pune, </span><br />
            <span style="margin-left: 65px;">Maharashtra 411021.</span><br />
          </div>
          <div>
            <img src="../../../../assets/images/footer/footer-address.png" alt="Indian Address" style="width:14px;height:20px;">
            <span> UAE &nbsp;- </span>
            <span>Tamouh Tower Reem Island, </span><br />
            <span style="margin-left: 67px;">Abu Dhabi, UAE.</span>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 contents mobileMiddle">
          <span style="font-weight: 500;margin-bottom: 8px;color: white;">Phone Number</span>
          <span>+918308099400</span>
          <span>+971561944600</span>
          <br />
          <span style="font-weight: 500;margin-bottom: 8px;color: white;">Email - </span>
          <span>admin@iceipts.com</span>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 contents follow">
          <span style="font-weight: 500;color: white;margin-bottom: 8px;">Address</span>
          <div class="subscribe-form">
            <form (ngSubmit)="subscribe()" #subscribeForm="ngForm">
              <input type="email"
                     id="email"
                     [(ngModel)]="email"
                     name="email"
                     #emailField="ngModel"
                     required
                     placeholder="Enter your email"
                     maxlength="30"
                     class="email-input"
                     [class.is-invalid]="emailField.invalid && submitted">
              <button type="submit" class="subscribe-button">Subscribe</button>
            </form>
          </div>
          <span style="color: white;font-weight: 500;margin-bottom: 8px;">Follow</span>
          <div>
            <a href="https://www.facebook.com/people/iCeipts/100063970088207/" target="_blank">
              <img src="../../../../assets/images/footer/facebook-background.png" alt = "Facebook" style="width:40px;height:40px;margin-right: 20px;" class="links">
            </a>
            <!-- <a href="https://www.facebook.com/people/iCeipts/100063970088207/" target="_blank">
              <img src="../../../../assets/images/footer/twitter-background.png" alt = "X" style="width:40px;height:40px;margin-right: 20px;" class="links">
            </a> -->
            <a href="https://www.youtube.com/@iCeiptsTechnology" target="_blank">
              <img src="../../../../assets/images/footer/youtube-background.png" alt = "YouTube" style="width:40px;height:40px;margin-right: 20px;" class="links">
            </a>
            <a href="https://www.linkedin.com/company/iceipts-technology/mycompany/" target="_blank">
              <img src="../../../../assets/images/footer/linkedin-background.png" alt = "Linkedin" style="width:40px;height:40px;margin-right: 20px;" class="links">
            </a>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="container-fluid">
    <div class = "row" style="max-width:600px;min-width:300px;gap:1rem;margin: auto;padding: 30px 0;display: flex;flex-wrap: wrap;align-items: center;justify-content: center;">
      <div style = "text-align: center;text-decoration: underline;vertical-align: middle;color:rgba(0, 0, 0, 1);cursor: pointer;" [routerLink]="['/home']">iCeipts Home</div>
      <div class="line"></div>
      <div style = "text-align: center;text-decoration: underline;vertical-align: middle;color:rgba(0, 0, 0, 1);cursor: pointer;" [routerLink]="['/policy/anti-spam-policy']">Anti-Spam Policy</div>
      <div class="line"></div>
      <div style = "text-align: center;text-decoration: underline;vertical-align: middle;color:rgba(0, 0, 0, 1);cursor: pointer;" [routerLink]="['/policy/terms-of-service']">Terms of Service</div>
      <div class="line"></div>
      <div style = "text-align: center;text-decoration: underline;vertical-align: middle;color:rgba(0, 0, 0, 1);cursor: pointer;" [routerLink]="['/policy/privacy-policy']">Privacy Policy</div>
    </div>
    <div class="row" style="padding: 10px 0 30px 0;display: flex;justify-content: center;">
      <div href = "#">© iCeipts Technology. All rights reserved.</div>
    </div>
  </div>


</section>
